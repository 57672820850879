import actions from './actions';

export const fields = {
  firstname: '',
  lastname: '',
  name: '',
  email: '',
  partner_lastname: '',
  partner_firstname: '',
  location: '',
  provider: 'password',
  logoUrl: null,
  isAdmin: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  editions: [],
  deletedAt: null,
  disabledAt: null
}

const initState = {
  isLoading: true,
  isLoadingDetails: true,
  errorMessage: false,
  users: {},
  modalActive: false,
  profile: Object.assign({}, fields),
  user: Object.assign({}, fields),
  userDetails: Object.assign({}, fields),
  userDetailsOrders: []
};

const populateFields = (values) => {
  if (values) {
    return {...fields, ...values}
  }
  return {...fields}
}

const populateData = (data) => {
  for (const property in data) {
    data[property] = populateFields(data[property])
  }
  return data
}

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: populateData(payload.data),
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors du chargement',
      };
    case actions.LOAD_LATEST_USERS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_LATEST_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: populateData(payload.data),
        errorMessage: false,
      };
    case actions.LOAD_LATEST_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors du chargement',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        user: populateFields(payload.data),
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        user: populateFields(payload.data),
      };
    case actions.LOAD_ENTITY_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        profile: populateFields(payload.data)
      };
    case actions.USER_LOAD_ENTITY_DETAILS_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        userDetails: payload.data,
        isLoadingDetails: false,
        errorMessage: false
      };
    case actions.USER_LOAD_ENTITY_DETAILS_ORDERS_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        userDetailsOrders: payload.data,
      }
    case actions.RESET_FIRESTORE_DOCUMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors du chargement',
      };
    case actions.SAVE_INTO_FIRESTORE_ERROR:
      console.log({
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors de l\'enregistrement',
      })
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors de l\'enregistrement',
      };
    default:
      return state;
  }
}
