class Actions {
    get(document) {
        const LOAD_FROM_FIRESTORE = document + 'LOAD_FROM_FIRESTORE';
        const LOAD_ENTITY_FROM_FIRESTORE_SUCCESS = document + 'LOAD_ENTITY_FROM_FIRESTORE_SUCCESS';
        const LOAD_FROM_FIRESTORE_SUCCESS = document + 'LOAD_FROM_FIRESTORE_SUCCESS';
        const LOAD_FROM_FIRESTORE_ERROR = document + 'LOAD_FROM_FIRESTORE_ERROR';

        const SAVE_INTO_FIRESTORE = document + 'SAVE_INTO_FIRESTORE';
        const SAVE_INTO_FIRESTORE_ERROR = document + 'SAVE_INTO_FIRESTORE_ERROR';

        const RESET_FIRESTORE_DOCUMENTS = document + 'RESET_FIRESTORE_DOCUMENTS';
        const RESET_FIRESTORE_DOCUMENTS_ERROR = document + 'RESET_FIRESTORE_DOCUMENTS_ERROR';

        const TOGGLE_FIRESTORE_HANDLE_MODAL = document + 'TOGGLE_FIRESTORE_HANDLE_MODAL';
        const FIRESTORE_UPDATE = document + 'FIRESTORE_UPDATE';

        const FIRESTORE_LOAD = document + 'FIRESTORE_LOAD';

        return ({
            LOAD_FROM_FIRESTORE,
            LOAD_ENTITY_FROM_FIRESTORE_SUCCESS,
            LOAD_FROM_FIRESTORE_SUCCESS,
            LOAD_FROM_FIRESTORE_ERROR,

            SAVE_INTO_FIRESTORE,
            SAVE_INTO_FIRESTORE_ERROR,

            RESET_FIRESTORE_DOCUMENTS,
            RESET_FIRESTORE_DOCUMENTS_ERROR,

            TOGGLE_FIRESTORE_HANDLE_MODAL,
            FIRESTORE_UPDATE,

            FIRESTORE_LOAD,

            loadFromFireStore: () => {
                return { type: LOAD_FROM_FIRESTORE };
            },

            loadEntityFromFireStore: (uid) => ({
                type: FIRESTORE_LOAD,
                payload: {uid}
            }),

            loadEntityFromFireStoreSuccess: data => ({
                type: LOAD_ENTITY_FROM_FIRESTORE_SUCCESS,
                payload: { data },
            }),

            loadFromFireStoreSuccess: data => ({
                type: LOAD_FROM_FIRESTORE_SUCCESS,
                payload: { data },
            }),

            loadFromFireStoreError: error => ({
                type: LOAD_FROM_FIRESTORE_ERROR,
                payload: { error },
            }),

            saveIntoFireStore: (data, actionName = 'insert') => ({
                type: SAVE_INTO_FIRESTORE,
                payload: { data, actionName },
            }),

            toggleModal: (data = null) => ({
                type: TOGGLE_FIRESTORE_HANDLE_MODAL,
                payload: { data },
            }),

            update: data => ({
                type: FIRESTORE_UPDATE,
                payload: { data },
            }),

            saveIntoFireStoreError: error => ({
                type: SAVE_INTO_FIRESTORE_ERROR,
                payload: { error },
            }),

            resetFireStoreDocuments: () => ({
                type: RESET_FIRESTORE_DOCUMENTS,
            }),

            resetFireStoreDocumentsError: error => ({
                type: RESET_FIRESTORE_DOCUMENTS_ERROR,
                payload: { error },
            }),
        })
    }
}

export default new Actions();
