import { db, rsf } from "@lepass/lib/firebase/firebase";
import { convertCollectionsSnapshotToMap } from "@lepass/lib/firebase/firebase.util";
import { call, put, all, takeEvery } from 'redux-saga/effects';
import actions from './actions';

const isDev = process.env.NODE_ENV !== 'production';

class Coupons {
    /**
     *
     * @returns {Promise<void>}
     */
    *loadFromFirestore({payload}) {
        try {
            const startTime = performance.now();
            const collectionRef = db
                .collection(`couponCampaigns/${payload.uid}/coupons`);
            const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
            const data = yield call(convertCollectionsSnapshotToMap, snapshots);
            yield put(actions.loadFromFireStoreSuccess(data));
            const endTime = performance.now()
            if (isDev) {
                console.log(`Loading collection took ${endTime - startTime} milliseconds`)
            }
        } catch (error) {
            console.error(error);
            yield put(actions.loadFromFireStoreError(error));
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.COUPON_LOAD, new Coupons().loadFromFirestore),
    ]);
}
