import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';

class Categories extends CollectionSagas {

    constructor() {
        super(actions,'categories');
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new Categories().loadFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, new Categories().storeIntoFirestore),
    ]);
}
