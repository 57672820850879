import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';

class Partners extends CollectionSagas {
    constructor() {
        super(actions,'partners', ['subCategory', 'highlights']);
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new Partners().loadFromFirestore),
        takeEvery(actions.FIRESTORE_LOAD, new Partners().loadEntityFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, new Partners().storeIntoFirestore),
    ]);
}
