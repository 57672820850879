import {db, rsf} from '@lepass/lib/firebase/firebase'
import {
    convertCollectionsSnapshotToMap
} from '@lepass/lib/firebase/firebase.util'
import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';
import { call, put } from "redux-saga/effects";
const isDev = process.env.NODE_ENV !== 'production';

class Order extends CollectionSagas {

    constructor() {
        super(actions,'orders', [], 'orderNumber', 'desc');
    }

    *loadHistory({payload}) {
        try {
            const startTime = performance.now();
            const collectionRef = db
              .collection(`orders/${payload.uid}/history`)
              .orderBy('updatedAt', 'asc');
            const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
            const data = yield call(convertCollectionsSnapshotToMap, snapshots);
            yield put(actions.loadOrderHistoryFromFireStoreSuccess(data));
            const endTime = performance.now()
            if (isDev) {
                console.log(`Loading collection took ${endTime - startTime} milliseconds`)
            }
        } catch (error) {
            console.error(error);
            yield put(actions.loadFromFireStoreError(error));
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new Order().loadFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, new Order().storeIntoFirestore),
        takeEvery(actions.FIRESTORE_LOAD, new Order().loadEntityFromFirestore),
        takeEvery(actions.LOAD_HISTORY_FROM_FIRESTORE, new Order().loadHistory),
    ]);
}
