import { call, put, all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';
import {db, rsf} from "@lepass/lib/firebase/firebase";
import {convertCollectionsSnapshotToMap} from "@lepass/lib/firebase/firebase.util";
import FirebaseAuth from "@lepass/auth/FirebaseAuth";
import Router from "next/router";
import {omit} from "lodash";
const isDev = process.env.NODE_ENV !== 'production';

class QrCodes extends CollectionSagas {

    constructor() {
        super(actions, 'qrcodes');
    }

    *loadQrCodes({payload}) {
        try {
            const startTime = performance.now();
            const collectionRef = db
                .collection(`discounts/${payload.uid}/qrcodes`)
                .orderBy('name', 'asc');
            const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
            const data = yield call(convertCollectionsSnapshotToMap, snapshots);
            yield put(actions.requestQrcodeFromFireStoreSuccess(data));
            const endTime = performance.now()
            if (isDev) {
                console.log(`Loading collection took ${endTime - startTime} milliseconds qrcode`)
            }
        } catch (error) {
            console.error(error);
            yield put(actions.loadFromFireStoreError(error));
        }
    }

    /**
     *
     * @param payload
     * @returns {Promise<void>}
     */
    *storeQrcodeIntoFirestore({payload}) {
        const {data, discountUid, actionName} = payload;
        try {
            const {email, uid} = yield FirebaseAuth.getUser();
            if (!uid) {
                Router.push('/signin');
                return null;
            }

            const now = new Date();
            const collectionName = `discounts/${discountUid}/qrcodes`;
            data.updatedAt = now;

            switch (actionName) {
                case 'delete':
                    data.deletedAt = now;
                    yield call(
                        rsf.firestore.deleteDocument,
                        `${collectionName}/${data.key}`,
                    );
                    break;
                case 'softDelete':
                    data.deletedAt = now;
                    yield call(
                        rsf.firestore.setDocument,
                        `${collectionName}/${data.key}`,
                        {
                            ...omit(data, ['key']),
                        }
                    );
                    break;
                case 'update':
                    data.updatedBy = {email, uid};
                    yield call(
                        rsf.firestore.setDocument,
                        `${collectionName}/${data.key}`,
                        {
                            ...omit(data, ['key']),
                        }
                    );
                    break;
                default:
                    data.createdAt = now;
                    data.createdBy = {email, uid};
                    yield call(rsf.firestore.addDocument, collectionName, data);
                    break;
            }

            yield put({type: actions.QRCODE_REQUESTED, payload: {uid: discountUid}});
        } catch (error) {
            console.error(error)
            yield put(this.actions.saveIntoFireStoreError(error));
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new QrCodes().loadFromFirestore),
        takeEvery(actions.QRCODE_REQUESTED, new QrCodes().loadQrCodes),
        takeEvery(actions.QRCODE_SAVE_REQUESTED, new QrCodes().storeQrcodeIntoFirestore),
    ]);
}
