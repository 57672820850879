import firebase from 'firebase/app';
import ReduxSagaFirebase from 'redux-saga-firebase';
import 'firebase/firestore';
import firebaseConfig from '@lepass/config/firebase.config';

const valid =
  firebaseConfig && firebaseConfig.apiKey && firebaseConfig.projectId;

let firebaseApp = {};
let firebaseAuth = {};
try {
  firebaseApp = firebase.initializeApp(firebaseConfig);
} catch (e) {}

class FirebaseHelper {
  isValid = valid;
  EMAIL = 'email';
  FACEBOOK = 'facebook';
  GOOGLE = 'google';
  GITHUB = 'github';
  TWITTER = 'twitter';

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.database = firebase.firestore();
    this.rsf = new ReduxSagaFirebase(firebaseApp, firebase.firestore());
    this.rsfFirestore = this.rsf.firestore;
  }

  createBatch = () => {
    return this.database.batch();
  };

  login(provider, info) {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    switch (provider) {
      case this.EMAIL:
        return firebase.auth().signInWithEmailAndPassword(
          info.email,
          info.password
        );
      case this.FACEBOOK:
        return firebase.auth().FacebookAuthProvider();
      case this.GOOGLE:
        return firebase.auth().GoogleAuthProvider();
      case this.GITHUB:
        return firebase.auth().GithubAuthProvider();
      case this.TWITTER:
        return firebase.auth().TwitterAuthProvider();
      default:
        throw new Error('Veuillez spécifier un provider')
    }
  }

  logout() {
    return firebase.auth().signOut();
  }

  isAuthenticated() {
    firebase.auth().onAuthStateChanged(user => {
      return !!user;
    });
  }

  getToken() {
    return this.getUser().getIdToken();
  }

  getUser() {
    return firebase.auth().currentUser
  }

  async isAdmin() {
    const userData = await this.getUserInfo()
    return userData.isAdmin === true && userData.level
  }

  async getUserInfo() {
    const {uid} = this.getUser();
    const snapshot = await this.database
      .collection('users')
      .doc(uid)
      .get()
    return snapshot.data()
  }

  resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  createNewRef() {
    return firebase
      .database()
      .ref()
      .push().key;
  }
}

export default new FirebaseHelper();
