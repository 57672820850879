import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';
import {db, rsf} from '@lepass/lib/firebase/firebase'
import { call, put } from "redux-saga/effects";

const isDev = process.env.NODE_ENV !== 'production';

class Users extends CollectionSagas {

    constructor() {
        super(actions,'users', [], 'lastname');

        this.loadEntityDetailsFromFirestore = this.loadEntityDetailsFromFirestore.bind(this);
    }

    *loadEntityDetailsFromFirestore({payload}) {
        try {
            const startTime = performance.now();
            const collectionRef = db
                .collection(this.collectionName)
                .doc(payload.uid);
            const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
            yield put(this.actions.loadEntityDetailsFromFireStoreSuccess(snapshots.data()));

            const collectionOrdersRef = db
                .collection('orders')
                .where("customer", "==", collectionRef)
            const snapshotsOrders = yield call(rsf.firestore.getCollection, collectionOrdersRef);
            let orders = []
            snapshotsOrders.forEach((doc) => {
                let order = doc.data();
                order.id = doc.id;
                orders.push(order)
            });
            yield put(this.actions.loadEntityDetailsOrdersFromFireStoreSuccess(orders));

            const endTime = performance.now()
            if (isDev) {
                console.log(`Loading collection 'users' took ${endTime - startTime} milliseconds`)
            }
        } catch (error) {
            console.error(error);
            yield put(this.actions.loadFromFireStoreError(error));
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new Users().loadFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, new Users().storeIntoFirestore),
        takeEvery(actions.FIRESTORE_LOAD, new Users().loadEntityFromFirestore),
        takeEvery(actions.USER_LOAD_ENTITY_DETAILS_FROM_FIRESTORE, new Users().loadEntityDetailsFromFirestore),
    ]);
}
