import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';
import {db} from "@lepass/lib/firebase/firebase";
import voucher_codes from "voucher-code-generator"

class CouponCampaigns extends CollectionSagas {
    constructor() {
        super(actions,'couponCampaigns', ['edition']);
    }

    *storeIntoFirestore({payload}) {
        const {key, createdAt, createdBy, updatedAt, name, count, prefix, type = null } = yield super.storeIntoFirestore({payload})
        const codeCount = type === 'code_count' ? count : 1
        const codes = voucher_codes.generate({
            length: 8,
            prefix: (prefix ?? 'PROMO').toUpperCase() + '-',
            pattern: '######',
            charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            count: codeCount
        });

        let batch = db.batch()
        for (let i = 0; i < codeCount; i++) {
            const docRef = db.collection(`couponCampaigns/${key}/coupons`).doc()
            batch.set(docRef, {
                createdAt,
                createdBy,
                updatedAt,
                used: null,
                usage: {},
                code: codes[i],
                url: `https://app.lepass.nc/promo/${codes[i]}`,
                type
            })

            if ((i + 1) % 500 === 0) {
                batch.commit().catch((e) => console.error(e))
                batch = db.batch()
            }
        }
        batch.commit().catch((e) => console.error(e))
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new CouponCampaigns().loadFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, new CouponCampaigns().storeIntoFirestore),
    ]);
}
