import actions from './actions';

export const fields = {
  title: '',
  image: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null,
};

const initState = {
  isLoading: true,
  errorMessage: false,
  thematics: {},
  modalActive: false,
  fields,
  thematic: Object.assign({}, fields),
};

const populateFields = (values) => {
  if (values) {
    return {...fields, ...values}
  }
  return {...fields}
}

const populateData = (data) => {
  for (const property in data) {
    data[property] = populateFields(data[property])
  }
  return data
}

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        thematics: populateData(payload.data),
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors du chargement',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        thematic: populateFields(payload.data),
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        thematic: populateFields(payload.data),
      };
    case actions.RESET_FIRESTORE_DOCUMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors du chargement',
      };
    case actions.SAVE_INTO_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors de l\'enregistrement',
      };
    default:
      return state;
  }
}
