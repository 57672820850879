import Actions from '../Collection/actions'

const actions = Actions.get('USER_');

actions.USER_LOAD_ENTITY_DETAILS_FROM_FIRESTORE = 'USER_LOAD_ENTITY_DETAILS_FROM_FIRESTORE'
actions.USER_LOAD_ENTITY_DETAILS_FROM_FIRESTORE_SUCCESS = 'USER_LOAD_ENTITY_DETAILS_FROM_FIRESTORE_SUCCESS'
actions.USER_LOAD_ENTITY_DETAILS_ORDERS_FROM_FIRESTORE_SUCCESS = 'USER_LOAD_ENTITY_DETAILS_ORDERS_FROM_FIRESTORE_SUCCESS'

actions.loadEntityDetailsFromFireStore = (uid) => ({
    type: actions.USER_LOAD_ENTITY_DETAILS_FROM_FIRESTORE,
    payload: {uid}
})

actions.loadEntityDetailsFromFireStoreSuccess = data => ({
    type: actions.USER_LOAD_ENTITY_DETAILS_FROM_FIRESTORE_SUCCESS,
    payload: { data },
})

actions.loadEntityDetailsOrdersFromFireStoreSuccess = data => ({
    type: actions.USER_LOAD_ENTITY_DETAILS_ORDERS_FROM_FIRESTORE_SUCCESS,
    payload: { data },
})

export default actions;
