import Actions from '../Collection/actions'

const actions = Actions.get('COUPON_');

actions.COUPON_LOAD = 'COUPON_LOAD';

actions.loadCouponsFromFireStore = (uid) => ({
    type: actions.COUPON_LOAD,
    payload: {uid},
})

export default actions;
