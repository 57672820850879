import { db } from "@lepass/lib/firebase/firebase";
import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';

class Discounts extends CollectionSagas {

    constructor() {
        super(actions,'discounts', ['edition']);
    }

    *storeIntoFirestore({payload}) {
        const {data, actionName} = payload;
        const { quantity } = data;
        delete data.quantity;
        payload.data = data;
        const {key, createdAt, createdBy, updatedAt, name } = yield super.storeIntoFirestore({payload})

        if (actionName === 'delete') {
            const batch = db.batch()
            db.collection(`discounts/${key}/qrcodes`)
              .get()
              .then(querySnapshot => {
                  querySnapshot.docs.forEach(doc => {
                      batch.delete(doc.ref)
                  })
                  batch.commit()
              })
        } else if (actionName === 'insert') {
            const batch = db.batch()
            for (let i = 0; i < quantity; i++) {
                const docRef = db.collection(`discounts/${key}/qrcodes`).doc()
                batch.set(docRef, {
                    createdAt,
                    createdBy,
                    updatedAt,
                    used: false,
                    name: `${name} QrCode #${(i + 1).toString().padStart(quantity.toString().length, '0')}`
                })
            }
            batch.commit()
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new Discounts().loadFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, new Discounts().storeIntoFirestore),
    ]);
}
