import actions from './actions';

export const fields = {
    qrcode: '',
    used: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    deletedAt: null,
};

const initState = {
    isLoading: true,
    errorMessage: false,
    fields,
    coupons: {},
};

const populateFields = (values) => {
    if (values) {
        return {...fields, ...values}
    }
    return {...fields}
}

const populateData = (data) => {
    for (const property in data) {
        data[property] = populateFields(data[property])
    }
    return data
}

export default function reducer(state = initState, { type, payload }) {
    switch (type) {
        case actions.LOAD_FROM_FIRESTORE:
            return {
                ...state,
                isLoading: true,
                errorMessage: false,
            };
        case actions.COUPON_LOAD:
            return {
                ...state,
                isLoading: true,
                errorMessage: false,
            };
        case actions.LOAD_FROM_FIRESTORE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                coupons: populateData(payload.data),
                errorMessage: false,
            };
        case actions.LOAD_FROM_FIRESTORE_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: 'Problème lors du chargement',
            };
        case actions.RESET_FIRESTORE_DOCUMENTS_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: 'Problème lors du chargement',
            };
        default:
            return state;
    }
}
