import { all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import CollectionSagas from '../Collection/sagas';

class SubCategories extends CollectionSagas {

    constructor() {
        super(actions, 'subCategories', ['category']);
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.LOAD_FROM_FIRESTORE, new SubCategories().loadFromFirestore),
        takeEvery(actions.SAVE_INTO_FIRESTORE, new SubCategories().storeIntoFirestore),
    ]);
}
