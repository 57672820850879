import { db, rsf } from "@lepass/lib/firebase/firebase";
import { convertCollectionsSnapshotToMap } from "@lepass/lib/firebase/firebase.util";
import Router from "next/router";
import { call, put } from "redux-saga/effects";
import FirebaseAuth from "@lepass/auth/FirebaseAuth";
import {omit, set} from 'lodash';

const isDev = process.env.NODE_ENV !== 'production';

class CollectionSagas {

    constructor(actions, collectionName, refs = [], orderBy = 'name', order = 'asc') {
        this.actions = actions;
        this.collectionName = collectionName;
        this.orderBy = orderBy;
        this.order = order;
        this.refs = refs;
        this.excludedRefData = [
            'createdBy',
            'createdAt',
            'updatedBy',
            'updatedAt',
            'deletedAt',
        ];

        this.loadFromFirestore = this.loadFromFirestore.bind(this);
        this.storeIntoFirestore = this.storeIntoFirestore.bind(this);
        this.loadEntityFromFirestore = this.loadEntityFromFirestore.bind(this);
    }

    /**
     *
     * @returns {Promise<void>}
     */
    *loadFromFirestore() {
        try {
            const startTime = performance.now();
            const collectionRef = db
                .collection(this.collectionName)
                .orderBy(this.orderBy, this.order);
            const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
            const data = yield call(convertCollectionsSnapshotToMap, snapshots);
            if (this.refs.length > 0) {
                for (let id in data) {
                    for (let prop in data[id]) {
                        if (this.refs.indexOf(prop) >= 0) {
                            let relations = Array.isArray(data[id][prop]) ? [] : {};
                            if (Array.isArray(data[id][prop])) {
                                for (let y in data[id][prop]) {
                                    relations.push(data[id][prop][y].path);
                                }
                            } else {
                                relations = {id: data[id][prop].path, promise: data[id][prop]};
                            }
                            data[id][prop] = relations;
                        }
                    }
                }
            }
            yield put(this.actions.loadFromFireStoreSuccess(data));
            const endTime = performance.now()
            if (isDev) {
                console.log(`Loading collection took ${endTime - startTime} milliseconds`)
            }
        } catch (error) {
            console.error(error);
            yield put(this.actions.loadFromFireStoreError(error));
        }
    }

    /**
     *
     * @returns {Promise<void>}
     */
    *loadEntityFromFirestore({payload}) {
        try {
            const startTime = performance.now();
            const collectionRef = db
              .collection(this.collectionName)
              .doc(payload.uid);
            const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
            yield put(this.actions.loadEntityFromFireStoreSuccess(snapshots.data()));
            const endTime = performance.now()
            if (isDev) {
                console.log(`Loading collection took ${endTime - startTime} milliseconds`)
            }
        } catch (error) {
            console.error(error);
            yield put(this.actions.loadFromFireStoreError(error));
        }
    }

    /**
     *
     * @param payload
     * @returns {Promise<void>}
     */
    *storeIntoFirestore({payload}) {
        const {data, actionName} = payload;
        try {
            const {email, uid} = yield FirebaseAuth.getUser();
            if (!uid) {
                Router.push('/signin');
                return null;
            }
            for (let i in this.refs) {
                const key = this.refs[i];
                const entry = data[key];
                if (Array.isArray(entry)) {
                    set(data, `${key}Data`, []);
                    for (let y in entry) {
                        const [collection, docId] = (entry && entry.id) ? entry[y].id.split('/') : entry[y].split('/');
                        data[key][y] = yield db.collection(collection).doc(docId);
                        const snapshot = yield db.collection(collection).doc(docId).get();
                        set(data, `${key}Data.${y}`, omit({...snapshot.data(), ...{id: docId}}, this.excludedRefData));
                    }
                } else {
                    const [collection, docId] = (entry && entry.id) ? entry.id.split('/') : entry.split('/');
                    data[key] = yield db.collection(collection).doc(docId);
                    const snapshot = yield db.collection(collection).doc(docId).get();
                    set(data, `${key}Data`, omit({...snapshot.data(), ...{id: docId}}, this.excludedRefData));
                }
            }
            const now = new Date();
            data.updatedAt = now;
            switch (actionName) {
                case 'delete':
                    data.deletedAt = now;
                    if (data.image) {
                        const sizes = ['1200x1200', '800x800', '400x400', '200x200'];
                        const splitted = data.image.split('/');
                        const name = splitted.pop();
                        const path = splitted.join('/');
                        const [file, ext] = name.split('.')
                        const toRemove = sizes.map((size) => (
                            `${path}/thumbs/${file}_${size}.${ext}`
                        ));
                        toRemove.push(data.image);
                        for (let i = 0; i < toRemove.length; i++) {
                            yield call(
                              rsf.storage.deleteFile,
                              toRemove[i]
                            )
                        }
                    }

                    yield call(
                      rsf.firestore.deleteDocument,
                      `${this.collectionName}/${data.key}`,
                    );
                    break;
                case 'softDelete':
                    data.deletedAt = now;
                    yield call(
                        rsf.firestore.setDocument,
                        `${this.collectionName}/${data.key}`,
                        {
                            ...omit(data, ['key']),
                        }
                    );
                    break;
                case 'update':
                    data.updatedBy = {email, uid};
                    yield call(
                        rsf.firestore.setDocument,
                        `${this.collectionName}/${data.key}`,
                        {
                            ...omit(data, ['key']),
                        }
                    );
                    break;
                default:
                    data.createdAt = now;
                    data.createdBy = {email, uid};
                    const document = yield call(rsf.firestore.addDocument, this.collectionName, data);
                    data.key = document.id
                    break;
            }
            yield put({type: this.actions.LOAD_FROM_FIRESTORE});
            return data
        } catch (error) {
            console.error(error)
            yield put(this.actions.saveIntoFireStoreError(error));
        }
    }
}

export default CollectionSagas;
