import Actions from '../Collection/actions'

const actions = Actions.get('QRCODE_');

actions.QRCODE_REQUESTED = 'QRCODE_REQUESTED';
actions.QRCODE_REQUESTED_SUCCESS = 'QRCODE_REQUESTED_SUCCESS';

actions.QRCODE_SAVE_REQUESTED = 'QRCODE_SAVE_REQUESTED';
actions.QRCODE_SAVE_REQUESTED_SUCCESS = 'QRCODE_SAVE_REQUESTED_SUCCESS';

actions.requestQrcodeFromFireStore = (uid) => ({
    type: actions.QRCODE_REQUESTED,
    payload: {uid},
})

actions.requestQrcodeFromFireStoreSuccess = data => ({
    type: actions.QRCODE_REQUESTED_SUCCESS,
    payload: { data },
})

actions.saveQrcodeIntoFireStore = (data, discountUid, actionName = 'insert') => ({
    type: actions.QRCODE_SAVE_REQUESTED,
    payload: { data, discountUid, actionName },
})

export default actions;
