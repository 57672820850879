import Actions from '../Collection/actions'

const actions = Actions.get('ORDER_');

const ORDER_LOAD_HISTORY_FROM_FIRESTORE = 'ORDER_LOAD_HISTORY_FROM_FIRESTORE';
const ORDER_LOAD_HISTORY_FROM_FIRESTORE_SUCCESS = 'ORDER_LOAD_HISTORY_FROM_FIRESTORE_SUCCESS';

actions.LOAD_HISTORY_FROM_FIRESTORE = ORDER_LOAD_HISTORY_FROM_FIRESTORE;
actions.LOAD_HISTORY_FROM_FIRESTORE_SUCCESS = ORDER_LOAD_HISTORY_FROM_FIRESTORE_SUCCESS;

actions.loadOrderHistoryFromFireStore = (uid) => ({
  type: actions.LOAD_HISTORY_FROM_FIRESTORE,
  payload: {uid},
})

actions.loadOrderHistoryFromFireStoreSuccess = data => ({
  type: actions.LOAD_HISTORY_FROM_FIRESTORE_SUCCESS,
  payload: { data },
})

export default actions;
