import actions from './actions';

export const fields = {
  billing: {},
  currency: '',
  customer: '',
  discount: 0,
  items: [],
  message: '',
  orderNumber: '',
  orderStatus: '',
  orderedItem: [],
  paymentMethod: '',
  tax: 0,
  total: 0,
  history: [],
  createdAt: null,
  updatedAt: null,
}

const initState = {
  isLoading: true,
  errorMessage: false,
  orders: {},
  history: {},
  modalActive: false,
  order: Object.assign({}, fields),
};

const populateFields = (values) => {
  if (values) {
    return {...fields, ...values}
  }
  return {...fields}
}

const populateData = (data) => {
  for (const property in data) {
    data[property] = populateFields(data[property])
  }
  return data
}

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: populateData(payload.data),
        errorMessage: false,
      };
    case actions.LOAD_HISTORY_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        history: {},
        errorMessage: false,
      }
    case actions.LOAD_HISTORY_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        history: payload.data,
        errorMessage: false,
      }
    case actions.LOAD_ENTITY_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        order: populateFields(payload.data),
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors du chargement',
      };
    case actions.LOAD_LATEST_USERS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_LATEST_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orders: populateData(payload.data),
        errorMessage: false,
      };
    case actions.LOAD_LATEST_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors du chargement',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        order: populateFields(payload.data),
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        order: populateFields(payload.data),
      };
    case actions.RESET_FIRESTORE_DOCUMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors du chargement',
      };
    case actions.SAVE_INTO_FIRESTORE_ERROR:
      console.log({
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors de l\'enregistrement',
      })
      return {
        ...state,
        isLoading: false,
        errorMessage: 'Problème lors de l\'enregistrement',
      };
    default:
      return state;
  }
}
